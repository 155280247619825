import React, { useState, useEffect, useRef } from "react";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { NavLink } from "react-router-dom";
import Moment from "moment";
import date from "date-and-time";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon
} from "react-share";
import lozad from "lozad";
import { ReactComponent as PlayIcon } from "../../assets/img/play.svg";
import { ReactComponent as LoveIcon } from "../../assets/img/love.svg";
import { ReactComponent as ShareIcon } from "../../assets/img/share.svg";
// image
import logoIcon from "../../assets/img/logoicon.svg";
import { postLikes, postShares } from "../API/Api";

const PlayCard = ({
  titleImg,
  titleDesc,
  titleName,
  titleVideo,
  videoid,
  addWishList,
  removeWishList,
  tags,
  icon,
  status,
  contentProviderId,
  dateTime
}) => {
  const { observe } = lozad("[data-use-lozad]", {
    loaded: (el) => {
      el.classList.add("fade");
    }
  });
  const [addBtn] = useState(localStorage.getItem("userId") ? true : false);
  const [like, setLike] = useState(false);
  const [share, setShare] = useState(false);

  const [mute, setMute] = useState(false);
  const fref = useRef(null);
  // useEffect(() => {
  //   if (localStorage.getItem("userName")) {
  //     setAddBtn(true);
  //   }
  // }, []);

  const handleLiveClick = () => {
    likeUpdate();
    setLike(!like);
  };
  const handleShareClick = () => {
    shareView();
    setShare(!share);
  };
  function playing() {
    if (status === "live" || status === "completed") {
      fref.current.player.play();
      console.log(fref.current.player.paused());
    }
    setMute(true);
  }
  function paused() {
    setMute(false);
    if (status === "live" || status === "completed") {
      fref.current.player.pause();
      console.log(fref.current.player.paused());
    }

    // if (status === "live" || status === "completed") {
    //   var playPromise = fref.current.player.play();
    //   if (playPromise !== undefined) {
    //     playPromise
    //       .then(() => {
    //         // Automatic playback started!
    //         // Show playing UI.
    //         fref.current.player.pause();
    //       })
    //       .catch((error) => {
    //         // Auto-play was prevented
    //         // Show paused UI.
    //       });
    //   }
    // }
  }
  useEffect(() => {
    observe();
  }, [observe]);
  Moment.locale("en");

  const shareView = () => {
    const now = new Date();
    const dt = date.format(now, "YYYY-MM-DDTHH:mm:ss.msZ");
    var msg = {
      liveSessionId: videoid,
      time_stamp: dt,
      userId: localStorage.getItem("userId"),
      source: "web"
    };
    // console.log(msg);
    postShares(msg).then((res) => {
      console.log("shareUpdated", res);
    });
  };
  const likeUpdate = () => {
    window.heart();
    const now = new Date();
    const dt = date.format(now, "YYYY-MM-DDTHH:mm:ss.msZ");
    var msg = {
      time_stamp: dt,
      liveSessionId: videoid,
      userId: localStorage.getItem("userId"),
      source: "web"
    };
    // console.log(msg);
    postLikes(msg).then((res) => {
      console.log("likeUpdated", res);
    });
  };
  return (
    <div
      className="playcard playhover"
      onMouseLeave={paused}
      onMouseEnter={playing}
    >
      <div className="playcard__wrapper">
        <div className="playcard__titleimg">
          <img src={titleImg} alt="img" />
          <div className="livetag">
            {/* <span>{status}</span> */}
            {status === "live" ? (
              <div>
                <span>Live</span>
              </div>
            ) : status === "scheduled" ? (
              <div>
                <span>Comingsoon</span>
              </div>
            ) : status === "planned" ? (
              <div>
                <span>Comingsoon</span>
              </div>
            ) : status === "completed" ? (
              <div>
                <span>Live</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="playcard__logo">
          <img src={logoIcon} alt="img" />
        </div>
      </div>
      <div className="playcard__view">
        <div className="playcard__titleimg">
          <VideoPlayer
            source={titleVideo}
            poster={titleImg}
            ref={fref}
            dis={mute}
          />
          {/* live */}
          <div className="livetag">
            {/* <span>{status}</span> */}
            {status === "live" ? (
              <div>
                <span>Live</span>
              </div>
            ) : status === "scheduled" ? (
              <div>
                <span>Comingsoon</span>
              </div>
            ) : status === "planned" ? (
              <div>
                <span>Comingsoon</span>
              </div>
            ) : status === "completed" ? (
              <div>
                <span>Live</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="playcard__view-desc">
          <div className="btn-wrapper">
            <NavLink
              exact
              to={{
                pathname: "/video-interation/" + videoid,
                videoUrl: titleVideo,
                contentProviderId: contentProviderId
              }}
              className="btn btn-icon"
              style={
                status === "scheduled" || status === "planned"
                  ? { pointerEvents: "none" }
                  : {}
              }
            >
              <PlayIcon className="stroke" />
            </NavLink>

            <button
              className={`btn btn-icon love-icon ${like ? "active" : ""}`}
              onClick={handleLiveClick}
            >
              <LoveIcon className="stroke" />
            </button>
            <button
              className={`btn btn-icon ${share ? "active" : ""}`}
              onClick={handleShareClick}
            >
              <ShareIcon className="fill" />
            </button>
            {/* {addBtn === true && (
              <button className="btn btn-icon" onClick={addWishList}>
                +
              </button>
            )} */}
            {icon === true && addBtn === true ? (
              <button className="btn btn-icon" onClick={removeWishList}>
                -
              </button>
            ) : icon === undefined && addBtn === true ? (
              <button className="btn btn-icon" onClick={addWishList}>
                +
              </button>
            ) : (
              ""
            )}
            <button className="like-hidden">
              <div id="hearContainer">
                <img
                  id="bigHeart"
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/183204/heart_(1).png"
                  alt="heart"
                  height="90%"
                  width="90%"
                />
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  id="bigHeart"
                >
                  <path
                    opacity="0.7"
                    d="M15.762 0.000511316C14.8555 -0.00535109 13.959 0.22965 13.1361 0.688863C12.3132 1.14808 11.5839 1.82027 11 2.65781C10.4199 1.81484 9.69143 1.13886 8.8676 0.679156C8.04378 0.219447 7.1454 -0.0124025 6.23797 0.000511316C4.58356 0.000511316 2.99691 0.794334 1.82706 2.20735C0.657213 3.62036 0 5.53682 0 7.53512C0 14.6661 10.3038 21.6289 10.7215 21.898C10.804 21.9645 10.9009 22 11 22C11.0991 22 11.196 21.9645 11.2785 21.898C11.6962 21.6289 22 14.767 22 7.53512C22 5.53682 21.3428 3.62036 20.1729 2.20735C19.0031 0.794334 17.4164 0.000511316 15.762 0.000511316ZM11 20.5525C9.38481 19.4089 1.11392 13.3206 1.11392 7.53512C1.11392 5.89366 1.65378 4.31942 2.61472 3.15874C3.57567 1.99805 4.87899 1.34598 6.23797 1.34598C7.09037 1.33246 7.93155 1.58175 8.68197 2.07027C9.43239 2.55878 10.0673 3.27039 10.5266 4.13782C10.5808 4.22814 10.652 4.30166 10.7343 4.35236C10.8166 4.40306 10.9076 4.42949 11 4.42949C11.0924 4.42949 11.1834 4.40306 11.2657 4.35236C11.348 4.30166 11.4192 4.22814 11.4734 4.13782C12.0778 3.02138 12.9646 2.17067 14.0018 1.71244C15.039 1.2542 16.1709 1.21297 17.2291 1.59488C18.2872 1.9768 19.2149 2.76141 19.8739 3.83184C20.5329 4.90226 20.8879 6.2012 20.8861 7.53512C20.8861 13.3879 12.6152 19.4425 11 20.5525Z"
                    fill="white"
                  />
                </svg>
              </div>
            </button>
          </div>
          <div className={`btn-share-content ${share ? "active" : ""}`}>
            <FacebookShareButton
              // url={`${window.location.href}video-interation/${videoid}`}
              url={`${window.location.host}/video-interation/${videoid}`}
              quote="CHECK OUT THIS - BUY DEGPEG PRODUCT"
              hashtag="#degpeglive"
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>

            {/* <EmailShareButton
              // url={`${window.location.href}video-interation/${videoid}`}
              url={`${window.location.host}/video-interation/${videoid}`}
              subject="CHECK OUT THIS - BUY DEGPEG PRODUCT"
              body="Hi Dear, Please Find Link below."
              separator=" :: "
            >
              <EmailIcon size={32} round={true} />
            </EmailShareButton> */}

            <TwitterShareButton
              // url={`${window.location.href}video-interation/${videoid}`}
              url={`${window.location.host}/video-interation/${videoid}`}
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>

            <WhatsappShareButton
              title={`CHECK OUT THIS - ${titleName} - ${titleDesc}`}
              // url={`${window.location.href}video-interation/${videoid}`}
              url={`${window.location.host}/video-interation/${videoid}`}
              separator=" :: "
            >
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>

            <LinkedinShareButton
              // url={`${window.location.href}video-interation/${videoid}`}
              url={`${window.location.host}/video-interation/${videoid}`}
            >
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </div>
          <h6>{titleName}</h6>
          <p>{titleDesc}</p>
          {tags && tags.length > 0 ? (
            <div className="tags-wrapper">
              {tags.map(
                (item, index) => index <= 2 && <span key={index}>{item}</span>
              )}
            </div>
          ) : (
            ""
          )}
          <div className="tags-wrapper">
            {" "}
            <span>{Moment(dateTime).format("DD MMMM, y @ hh:mm A")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PlayCard);
