import axios from "axios";
import DegpegClient from "../DegpedSdk/DegpegClientSdk.jsx";
import AWS from "aws-sdk";
// Local Server
export const baseUrl = "https://demo.api.degpeg.com";
// Production Server
// export const baseUrl = "https://prod.api.degpeg.com";
// const contentProviderId = "61cb07913e887fa48e688594";
// const contentPublisherId = "6007cf41f2895e2eabcc2ac2";
const contentPublisherId = "621ca986138bae64d5ec99ec";

//DegpegClient  sdk  instantiation
let DegpegSdk = new DegpegClient(baseUrl, contentPublisherId);

//Aws
const S3_BUCKET = "degpeg-file-storage";
const REGION = "ap-south-1";
AWS.config.update({
  accessKeyId: "AKIAX3JPB7XV5JT2726E",
  secretAccessKey: "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"
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION
});

export async function getAllContentProviderIds() {
  const response = await DegpegSdk.getAllContentProviderIds();

  return response;
}
export async function getAllSessionsOfContentProvider(id) {
  const response = await DegpegSdk.getAllSessionsOfContentProvider(id);
  return response;
}
export async function getAllSessionsForcontentProvider() {
  const response = await DegpegSdk.getAllContentProviderIds();
  const allsessionPromises = await response.map((item) => {
    async function getData() {
      const data = await DegpegSdk.getAllSessionsOfContentProvider(item);
      return data;
    }
    return getData();
  });
  const promise4all = Promise.all(allsessionPromises.flat());
  const allSessionsArray = (await promise4all).flat();

  // eslint-disable-next-line
  const allSessionsWithChannels = allSessionsArray.map((data) => {
    //filter data based on channelIds
    // console.log("sessionData", data);
    if (data.channelIds && data.channelIds.length > 0) {
      // eslint-disable-next-line
      const temp = data.channelIds.map((item) => {
        //looping through channelid and get data having channel name equals Stream To Website
        if (item !== "61cb099f3e887fa48e688598") {
          return getChannelIds(item).then((res) => {
            if (res.name === "Stream To Website") {
              return res;
            }
          });
        }
      });

      if (temp.length > 0) {
        return data;
      }
    }
  });
  const result = allSessionsWithChannels.filter((item) => {
    return item !== undefined && item.liveSessionCategoryId !== "";
  });

  //removing duplicate values
  const allSessions = result
    .map((e) => e["id"])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((e) => result[e])
    .map((e) => result[e]);
  //getting data having liveSessionCategoryId
  // console.log(allSessions);
  return allSessions;
}

export async function getAllLiveSessionsProductCategory() {
  const response = await DegpegSdk.getAllLiveSessionsProductCategory();

  return response.data;
}

export async function getAllSessionData() {
  const response = await DegpegSdk.getAllSessionData();

  return response.data;
}
export async function getChannelIds(channelId) {
  const response = await DegpegSdk.getChannelIds(channelId);

  return response.data;
}

export async function getAllContentProviders(id) {
  const response = await DegpegSdk.getAllContentProviders(id);

  return response.data;
}
export async function getContentProviderUserDetails(id) {
  const response = await DegpegSdk.getContentProviderUserDetails(id);
  console.log(response);
  return response;
}

export async function getSesseionChatMessages(id) {
  const response = await DegpegSdk.getSesseionChatMessages(id);

  return response.data;
}
export async function postSessionChatMessages(msg) {
  const response = await DegpegSdk.postSessionChatMessages(msg);

  return response;
}
export async function getAllUserLoginDetails() {
  const response = await DegpegSdk.getAllUserLoginDetails();

  return response;
}
export async function getUserLoginDetails(id) {
  const response = await DegpegSdk.getUserLoginDetails(id);

  return response;
}

export async function getAllProdcts(id, contentProviderId) {
  const response = await DegpegSdk.getAllProdcts(id, contentProviderId);

  return response;
}
export async function getProdct(productId) {
  const response = await DegpegSdk.getProdcts(productId);

  return response;
}
export async function getCta(ctaId) {
  const response = await DegpegSdk.getCtas(ctaId);

  return response;
}

export async function postViews(data) {
  const response = await DegpegSdk.postViews(data);

  return response;
}
export async function postLikes(data) {
  const response = await DegpegSdk.postLikes(data);

  return response;
}
export async function postShares(data) {
  const response = await DegpegSdk.postShares(data);

  return response;
}
export async function postPurchases(data) {
  const response = await DegpegSdk.postPurchases(data);

  return response;
}
export async function getViewsCount(id) {
  const response = await DegpegSdk.getViewsCount(id);

  return response;
}
export async function getSharesCount(id) {
  const response = await DegpegSdk.getSharesCount(id);

  return response;
}
export async function getLikesCount(id) {
  const response = await DegpegSdk.getLikesCount(id);

  return response;
}

export async function getPurchaseCount(id) {
  const response = await DegpegSdk.getPurchaseCount(id);

  return response;
}
//socketConnection
export function socketConnection(id) {
  const response = DegpegSdk.socketConnection(id);
  return response;
}

// Api calls without sdk
export async function postUserLoginDetails(obj) {
  const response = await axios({
    method: "POST",
    url: `${baseUrl}/users/login`,
    headers: { "Content-Type": "application/json" },
    data: obj
  }).then((response) => response);
  return await response;
}
export async function changeUserDetails(id, obj) {
  const response = await axios({
    method: "PATCH",
    url: `${baseUrl}/users/${id}`,
    headers: { "Content-Type": "application/json" },
    data: obj
  }).then((response) => console.log(response));
  return await response;
}
export async function postResetPassword(obj) {
  const response = await axios({
    method: "POST",
    url: `${baseUrl}/users/resetpassword`,
    headers: { "Content-Type": "application/json" },
    data: obj
  }).then((response) => response);
  return await response;
}
export async function postNotification(obj) {
  console.log("notification", obj);
  const response = await axios({
    method: "POST",
    url: `${baseUrl}/users/send-lead-data`,
    headers: { "Content-Type": "application/json" },
    data: obj
  }).then((response) => response);
  return await response;
}
export async function postOtp(register) {
  let result = await fetch(`${baseUrl}/users/signup`, {
    method: "POST",
    body: JSON.stringify(register),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  })
    .then((res) => res)
    .catch((err) => err);
  return result;
}
export async function patchverifyOtp(id) {
  const response = axios
    .patch(`${baseUrl}/users/${id}`, {
      status: "active"
    })
    .then((response) => response);
  return await response;
}
export async function patchGenerateNewPassword(searchParams, obj) {
  const response = axios
    .patch(
      `${baseUrl}/user-credentials/update/${searchParams.get("userId")}`,
      obj
    )
    .then((response) => response);
  return await response;
}
export async function patchChangePassword(userId, obj) {
  const response = axios
    .patch(`${baseUrl}/user-credentials/update/${userId}`, obj)
    .then((response) => response);
  return await response;
}

//Aws upload profile image
//  export asyc function uploadFile(folderName, file) {
//   const contentType = file.type;
//   let userRoleId: string;
//   if (localStorage.getItem("role") === "influencer") {
//     userRoleId = localStorage.getItem("influencerId");
//   } else {
//     userRoleId = localStorage.getItem("contentProviderId");
//   }
//   const bucket = new S3({
//     accessKeyId: "AKIAX3JPB7XV5JT2726E",
//     secretAccessKey: "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",
//     region: "ap-south-1",
//   });

//   const params = {
//     Bucket: "degpeg-file-storage",
//     Key: `${folderName}/${
//       new Date().toISOString() + "_" + userRoleId + "_" + file.name
//     }`,
//     Body: file,
//     ACL: "public-read",
//     ContentType: contentType,
//   };

//   return new Promise((resolve, reject) => {
//     bucket.upload(params, function (err, data) {
//       if (err) {
//         console.log("There was an error uploading your file: ", err);
//         reject(err);
//         //return false;
//       } else {
//         console.log("Successfully uploaded file.", data);
//         let location = data.Location.split("south-1.amazonaws.com")[1];
//         data.Location = "https://d3ur2dp0uh3ite.cloudfront.net" + location;
//         resolve(data);
//         //return true;
//       }
//     });
//   });
// }

export function uploadFile(selectedFile, userId) {
  const file = `users-dp/${
    new Date().toISOString() + "_" + userId + "_" + selectedFile.name
  }`;
  console.log(file);
  const params = {
    ACL: "public-read",
    Body: selectedFile,
    Bucket: S3_BUCKET,
    Key: file,
    ContentType: selectedFile.type
  };
  return new Promise((resolve, reject) => {
    myBucket.upload(params, function (err, data) {
      if (err) {
        console.log("There was an error uploading your file: ", err);
        reject(err);
        //return false;
      } else {
        console.log("Successfully uploaded file.", data);
        let location = data.Location.split("south-1.amazonaws.com")[1];
        data.Location = "https://d3ur2dp0uh3ite.cloudfront.net" + location;
        resolve(data);
        //return true;
      }
    });
  });
}
