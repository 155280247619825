import { React, PureComponent } from "react";
import { NavLink } from "react-router-dom";
import LoginModal from "../Modal/LoginModal";
// images
import logoIcon from "../../assets/img/logo.svg";
import profileicon from "../../assets/img/user.svg";
import profile from "../../assets/img/profile.svg";
// import cartIcon from "../../assets/img/cart.svg";
// import myorderIcon from "../../assets/img/myorder.svg";
// import settingIcon from "../../assets/img/settings.svg";
import favouriteIcon from "../../assets/img/favourite.svg";
import logoutIcon from "../../assets/img/logout.svg";
import { getToken, removeUserSession } from "../utlis";

import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import { getUserLoginDetails } from "../API/Api";
class Header extends PureComponent {
  constructor(props) {
    super(props);
    const token = getToken();
    let loggedin = true;
    if (token == null) {
      loggedin = false;
    }
    this.state = {
      showModal: false,
      open: false,
      checkedSignIn: false,
      loggedin,
      userName: "",
      profileImage: null
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showToast = this.showToast.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem("userName")) {
      this.setState({ userName: localStorage.getItem("userName") });
    }
    if (localStorage.getItem("userId")) {
      getUserLoginDetails(localStorage.getItem("userId")).then((res) => {
        this.setState({
          userName: res.data.firstName,
          profileImage: res.data.avatar ? res.data.avatar : profileicon
        });
        // this.setState({})
      });
      this.setState({
        profileImage: JSON.parse(localStorage.getItem("profileImg"))
      });
    }
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }
  handleCloseModal() {
    this.setState({ showModal: false });
    if (localStorage.getItem("userName")) {
      this.setState({ userName: localStorage.getItem("userName") });
    }
  }
  handleButtonClick = () => {
    this.setState((state) => {
      return {
        open: !state.open
      };
    });
  };
  handleLogout = (e) => {
    e.preventDefault();
    removeUserSession();
    localStorage.removeItem("userName");
    this.setState({ userName: "" });

    localStorage.removeItem("userId");
    window.localStorage.clear();
    this.setState({ userId: "" });
    console.log("logout");

    this.props.history.push("/");
  };
  showToast() {
    // alert("1112");
    toast.success("Login SuccessFull", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      draggablePercent: 60,
      fontSize: "24px"
    });
  }
  showToast1() {
    toast.error(
      "Accout doesnot exist with this mail id, please create an account using this mail id!",
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000
      }
    );
  }
  showToast2() {
    toast.info("Please Check Your Email For ResetPassword!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 4000
    });
  }
  showToast3() {
    toast.info("Account created sucessfully! please login!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 4000
    });
  }
  render() {
    return (
      <header className="header signin">
        <div className="container">
          <div className="flex-between align-center">
            <a href="/" className="logo">
              <img src={logoIcon} alt="logo" />
            </a>
            <nav className={`header__navbar ${this.state.open ? "open" : ""}`}>
              <div className="hamburger" onClick={this.handleButtonClick}>
                <span className="hamburger__menu"></span>
              </div>
              <ul>
                <li>
                  <NavLink exact activeClassName="active" to="/">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/category">
                    Categories
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/liveshows">
                    Live Shows
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/calendar">
                    Calendar
                  </NavLink>
                </li>
                <li>
                  {/* {this.state.loggedin ? ( */}
                  {this.state.userName === "" && (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={this.handleOpenModal}
                    >
                      <span>Login / Signup</span>
                    </button>
                  )}
                  {/* ) : ( */}
                  {this.state.userName !== "" && (
                    <div className="profile__card">
                      <a href="#profile" className="profile__icon">
                        {this.state.profileImage ? (
                          <img
                            src={this.state.profileImage}
                            alt="profile"
                            style={{ borderRadius: "50%" }}
                          />
                        ) : (
                          <img
                            src={profileicon}
                            alt="profile"
                            style={{ borderRadius: "50%" }}
                          />
                        )}
                        Hi, {this.state.userName}
                      </a>
                      <ul className="profile__menu">
                        {/* <li>
                          <a href="/">
                            <img src={cartIcon} alt="cart" />
                            <span>
                              {localStorage.getItem("cart")
                                ? JSON.parse(localStorage.getItem("cart"))
                                    .length
                                : 0}
                            </span>
                            Cart
                          </a>
                        </li> */}
                        {/* <li>
                          <a href="/">
                            <img src={myorderIcon} alt="order" />
                            MyOrders
                          </a>
                        </li> */}
                        <li>
                          <NavLink to="/profile">
                            <img src={profile} alt="profile" />
                            Profile
                          </NavLink>
                        </li>
                        {/* <li>
                          <a href="#profile">
                            <img src={settingIcon} alt="setting" />
                            Settings
                          </a>
                        </li> */}
                        <li>
                          <NavLink to="/favorites">
                            <img src={favouriteIcon} alt="fav" />
                            Favorites
                          </NavLink>
                        </li>
                        <li>
                          <a href="/" onClick={this.handleLogout}>
                            <img src={logoutIcon} alt="Logout" />
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                  {/* )} */}
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <LoginModal
          modalOpen={this.state.showModal}
          closeModal={this.handleCloseModal}
          showToat={this.showToast}
          showToast1={this.showToast1}
          showToast2={this.showToast2}
          showToast3={this.showToast3}
        />
        <ToastContainer
          limit={1}
          transition={Slide}
          style={{ fontSize: "16px" }}
        />
      </header>
    );
  }
}
export default withRouter(Header);
