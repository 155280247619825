import React from 'react';
import ReactDOM from 'react-dom';
import ShopifyApp from './components/shopify/ShopifyApp';
import Client from 'shopify-buy';
import { Provider } from 'react-redux';
import store from './components/shopify/store';
import Routes from './routes';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './components/shopify/style/shopify.css';
// import 'react-chat-widget/lib/styles.css';
import './App.scss';

const client = Client.buildClient({
  storefrontAccessToken: 'b87b8d7002263d14ef0ab354fc40d76a',
  domain: 'degpeg-test.myshopify.com'
});
store.dispatch({type: 'CLIENT_CREATED', payload: client});

// buildClient() is synchronous, so we can call all these after!
client.product.fetchAll().then((res) => {
store.dispatch({type: 'PRODUCTS_FOUND', payload: res});
});
client.checkout.create().then((res) => {
store.dispatch({type: 'CHECKOUT_FOUND', payload: res});
});
client.shop.fetchInfo().then((res) => {
store.dispatch({type: 'SHOP_FOUND', payload: res});
});

ReactDOM.render(
  <Provider store={store}>
  <Routes />
  <ShopifyApp />
  </Provider>,
  document.getElementById('root')
);
